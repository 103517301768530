
const Card = ({children, width = '60%', height = '80%'}) => {
  return (
    <div
      className={'card'}
      style={{
        padding: 16,
        backgroundColor: 'white',
        borderRadius: 60,
        width: width,
        height: height,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>
      <div
        className={'child-container'}
        style={{
          border: '3px dotted #000',
          padding: 32,
          borderRadius: 60,
          display: 'flex',
          flex: '1',
        }}>
        {children}
      </div>
    </div>
  );
};

export default Card;
