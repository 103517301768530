import React from 'react';
import {Store} from 'react-notifications-component';

const _settings = {
  insert: 'top',
  container: 'bottom-right',
  animationIn: ['animate__animated', 'animate__fadeIn'],
  animationOut: ['animate__animated', 'animate__fadeOut'],
  dismiss: {
    duration: 5000,
    pauseOnHover: true,
    onScreen: true,
    showIcon: true,
    waitForAnimation: true,
  },
};

export const _loader = () => {
  let overlay = document.createElement('div');
  overlay.id = 'overlay-loader';
  overlay.className = 'offcanvas-backdrop fade show';

  let wrap = document.createElement('div');
  wrap.className = 'overlay__inner';

  let content = document.createElement('div');
  content.className = 'overlay__content';

  let span = document.createElement('span');
  span.className = 'spinner';

  content.appendChild(span);
  wrap.appendChild(content);
  overlay.appendChild(wrap);
  document.querySelector('body').append(overlay);
};

export const _removeLoader = () => {
  let ele = document.getElementById('overlay-loader');
  if (ele) {
    ele.remove();
  }
};

export const _notify = (message, type = 'default') => {
  let icon = '';
  let stringTitle = '';
  if (type === 'success') {
    icon = 'CheckCircle';
    stringTitle = 'Exito';
  }
  if (type === 'danger') {
    icon = 'XCircle';
    stringTitle = 'Error';
  }
  if (type === 'warning') {
    icon = 'ExclamationTriangle';
    stringTitle = 'Atención';
  }
  if (type === 'info') {
    icon = 'InfoCircle';
    stringTitle = 'Info';
  }

  let title = (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}>
      {/*<Icon icon={icon} style={{fontSize: '18px', color: 'white', marginRight: '5px'}}/>*/}
      <p style={{fontSize: '14px', margin: '0px'}}>{stringTitle}</p>
    </div>
  );

  let tpl = (
    <p
      style={{
        fontSize: '14px',
        margin: '0px',
      }}>
      {message}
    </p>
  );

  message = tpl;

  Store.addNotification({
    title,
    message,
    type,
    ..._settings,
  });
};
