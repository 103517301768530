import './styles.css';
import flash from '../../images/flash.png';
import click from '../../sounds/click.mp3';

const Button = ({order, title, selected = false, onClickButton}) => {
  const sound = new Audio(click);

  const onClick = () => {
    sound.play();
    onClickButton();
  };
  return (
    <div
      onClick={() => onClick()}
      className={`button ${selected && 'selected'}`}>
      <img className={'flash'} src={flash} alt={'flash'} />
      <span className={'order'}>{order}</span>
      <span className={'title'}>{title}</span>
    </div>
  );
};

export default Button;
