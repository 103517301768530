import styles from './styles.css';
import MMSTimer from '../MMSTimer/MMSTimer';

const SceneGame = ({isStaging, stateGame, stateConfigGame, callback, onTransitionEnd}) => {
  const {message, selectedCard, stateBg} = stateGame;
  return (
    <div
      className={`game-container ${stateBg}`}
      onTransitionEnd={onTransitionEnd}>
      <div className={'content'}>
        <div />
        {!message ? (
          <p className={'card-selected'}>{selectedCard?.text}</p>
        ) : (
          <p className={'message'}>{message}</p>
        )}
        <MMSTimer minutes={stateConfigGame.time} isRunning={isStaging} callback={callback} />
      </div>
    </div>
  );
};

export default SceneGame;
