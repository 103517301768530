import React, {createContext, useState} from 'react';

export const DataContext = createContext();

export const DataProvider = ({children}) => {
  const [user, setUser] = useState(null);
  const [responseError, setResponseError] = useState(null);

  return (
    <DataContext.Provider
      value={{
        user,
        setUser,
        responseError,
        setResponseError,
      }}>
      {children}
    </DataContext.Provider>
  );
};
