export const APP_ENV = 'production';

const environments = {
  local: {
    domain: 'http://127.0.0.1:8000',
    base_url: 'http://127.0.0.1:8000/api',
  },
  ngrok: {
    domain: 'http://localhost:8080',
    base_url: 'http://localhost:8080',
  },
  production: {
    domain: 'https://api.ideaflash.app',
    base_url: 'https://api.ideaflash.app/api',
  },
};

export const DOMAIN = environments[APP_ENV].domain;

export const BASE_URL = environments[APP_ENV].base_url;

export const VERSION = 'Versión 1.0';
