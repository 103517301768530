import api from './api';

export const getUser = async () => {
  try {
    let user = localStorage.getItem('user');
    if (user) {
      user = JSON.parse(user);
      return user;
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const logout = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = '/auth/logout';
      let response = await api.post(url);
      localStorage.clear();
      resolve(response)
    } catch (error) {
      reject(error);
      console.log(error);
    }
  });
};

const logoutLight = async () =>{
    localStorage.clear()
}