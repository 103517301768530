import React, {useEffect, useRef, useState} from 'react';
import soundClick from '../../sounds/click.mp3';
import audioCorrect from '../../sounds/sound_correct.mp3';
import audioSkip from '../../sounds/sound_skip.mp3';
import Orientation from '../../components/Orientation/Orientation';
import Options from '../../components/Options/Options';
import api from '../../utils/api';
import Timer from '../../components/Timer/Timer';
import GameScene from '../../components/GameScene/GameScene';
import Score from '../../components/Score/Score';
import SlideOver from '../../components/SlideOver/SlideOver';
import iconMenu from '../../images/icon-menu.png';
import {_notify} from "../../utils/helpers";

const Game = () => {
  const soundButton = useRef(new Audio(soundClick));
  const soundCorrect = useRef(new Audio(audioCorrect));
  const soundSkip = useRef(new Audio(audioSkip));

  const [isSlideOverOpen, setSlideOverOpen] = useState(false);

  const [stateLevels, setStateLevels] = useState([]);
  const [stateLevel, setStateLevel] = useState(null);

  const [stateTeam, setStateTeam] = useState(null);
  const [stateTeams, setStateTeams] = useState([
    {
      id: 1,
      name: 'Equipo',
      selected: false,
    },
    {
      id: 2,
      name: 'Equipos',
      selected: false,
    },
    {
      id: 3,
      name: 'Equipos',
      selected: false,
    },
    {
      id: 4,
      name: 'Equipos',
      selected: false,
    },
  ]);

  const [stateCards, setStateCards] = useState([
    {
      id: 1,
      card: 10,
      selected: false,
    },
    {
      id: 2,
      card: 20,
      selected: false,
    },
    {
      id: 3,
      card: 30,
      selected: false,
    },
    {
      id: 4,
      card: 40,
      selected: false,
    },
    {
      id: 5,
      card: 50,
      selected: false,
    },
  ]);
  const [stateTime, setStateTime] = useState([
    {
      id: 1,
      time: 1,
      selected: false,
    },
    {
      id: 2,
      time: 2,
      selected: false,
    },
    {
      id: 3,
      time: 3,
      selected: false,
    },
    {
      id: 4,
      time: 4,
      selected: false,
    },
    {
      id: 5,
      time: 5,
      selected: false,
    },
  ]);

  const [timerIsRunning, setTimerIsRunning] = useState(false);

  const [isStaging, setIsStaging] = useState(false);
  const [gameIsOver, setGameIsOver] = useState(false);

  const [blockRotation, setStateBlockRotation] = useState(false);
  const [stateOrientation, setStateOrientation] = useState({
    orientation: 'positive',
    orientationActive: false,
    deviceOrientationPermission: false,
  });

  const [stateGame, setStateGame] = useState({
    availableCards: [],
    selectedCard: null,
    message: '',
    score: [],
    stateBg: '',
  });

  const [stateConfigGame, setStateConfigGame] = useState({
    maxCards: 10,
    time: 60,
  });

  // REFS
  const isStagingRef = useRef(isStaging);
  const gameIsOverRef = useRef(gameIsOver);
  const blockRotationRef = useRef(blockRotation);
  const orientationRef = useRef(stateOrientation.orientation);
  const availableCardsRef = useRef(stateGame.availableCards);
  const stateBgRef = useRef(stateGame.stateBg);

  useEffect(() => {
    const {stateBg} = stateGame;
    if (stateBg === 'bg-correct') {
      soundCorrect.current.play().catch(error => {
        console.log('Error al reproducir el sonido:', error);
      });
    } else if (stateBg === 'bg-skip') {
      soundSkip.current.play().catch(error => {
        console.log('Error al reproducir el sonido:', error);
      });
    }
  }, [stateGame.stateBg, stateGame.selectedCard]);

  useEffect(() => {
    const {orientation} = stateOrientation;
    const {availableCards, stateBg} = stateGame;

    isStagingRef.current = isStaging;
    gameIsOverRef.current = gameIsOver;
    blockRotationRef.current = blockRotation;
    orientationRef.current = orientation;
    availableCardsRef.current = availableCards;
    stateBgRef.current = stateBg;
  }, [
    isStaging,
    gameIsOver,
    blockRotation,
    stateOrientation.orientation,
    stateGame.availableCards,
    stateGame.stateBg,
  ]);

  useEffect(() => {
    getLevels();
  }, []);

  useEffect(() => {
    window.addEventListener('orientationchange', onOrientationChange);
    window.addEventListener('devicemotion', onDeviceMotion);
    return () => {
      window.removeEventListener('orientationchange', onOrientationChange);
      if (typeof DeviceOrientationEvent.requestPermission !== 'function') {
        window.removeEventListener(
          'deviceorientation',
          onDeviceOrientation,
          true,
        );
      }
      window.removeEventListener('devicemotion', onDeviceMotion);
    };
  }, []);

  const openSlideOver = () => setSlideOverOpen(true);
  const closeSlideOver = () => setSlideOverOpen(false);

  const getDeviceOrientationPermission = () => {
    if (
      typeof DeviceOrientationEvent.requestPermission === 'function' &&
      !stateOrientation.deviceOrientationPermission
    ) {
      DeviceOrientationEvent.requestPermission()
        .then(response => {
          if (response === 'granted') {
            setStateOrientation(prevState => ({
              ...prevState,
              deviceOrientationPermission: true,
            }));
            window.addEventListener(
              'deviceorientation',
              onDeviceOrientation,
              true,
            );
          } else {
            alert(
              'No podrás jugar este juego sin permitir la detección de Orientación y Movimiento. Cierra la App y Acepte nuevamente',
            );
          }
        })
        .catch(error => {
          alert(error);
        });
    } else {
      if (!stateOrientation.deviceOrientationPermission) {
        setStateOrientation(prevState => ({
          ...prevState,
          deviceOrientationPermission: true,
        }));
        window.addEventListener('deviceorientation', onDeviceOrientation, true);
      }
    }
  };

  const onOrientationChange = event => {
    if (window.orientation === 90) {
      setStateOrientation(prevState => ({
        ...prevState,
        orientation: 'positive',
        orientationActive: true,
      }));
    } else if (window.orientation === -90) {
      setStateOrientation(prevState => ({
        ...prevState,
        orientation: 'negative',
        orientationActive: true,
      }));
    }
  };

  const onDeviceMotion = event => {
    if ((isStaging || !gameIsOver) && event.acceleration.x > 30) {
      //REALOAD GAME
      // MOVIL SHAKE
    }
  };

  const onDeviceOrientation = event => {
    if (isStagingRef.current) {
      if (Math.abs(event.gamma) <= 30 && !blockRotationRef.current) {
        setStateBlockRotation(true);
        console.log('gama', event.gamma);
        console.log('orientation', orientationRef.current);
        if (event.gamma < 0) {
          if (orientationRef.current === 'positive') {
            getNextItem('bg-skip');
          } else {
            getNextItem('bg-correct');
          }
        } else {
          if (orientationRef.current === 'positive') {
            getNextItem('bg-correct');
          } else {
            getNextItem('bg-skip');
          }
        }
      }
      if (Math.abs(event.gamma) >= 70) {
        console.log('GAMA 70', Math.abs(event.gamma));
        setStateBlockRotation(false);
      }
    }
  };

  const getNextItem = decision => {
    const availableCardsLength = availableCardsRef.current.length;
    const availableCards = availableCardsRef.current;

    console.log('cards', availableCardsLength);
    console.log('cards', availableCardsLength);
    // if (availableCardsLength === 0) {
    //   endGame();
    //   return;
    // }

    console.log('entro al next');

    // Elegir una tarjeta aleatoria de las disponibles
    let randomCard = null;
    let updatedAvailableCards = [];
    if (availableCardsLength > 0) {
      const randomIndex = Math.floor(Math.random() * availableCardsLength);
      randomCard = availableCards[randomIndex];

      updatedAvailableCards = availableCards.filter(
        card => card.id !== randomCard.id,
      );
    }

    if (isStagingRef.current) {
      setStateGame(prevState => {
        if (decision === 'bg-correct') {
          const lastOrder = Math.max(
            ...prevState.score.map(item => item.order),
          );

          // Actualiza el score solo si el objeto tiene el último order
          const updatedScore = prevState.score.map(
            item =>
              item.order === lastOrder
                ? {...item, score: item.score + 1} // Incrementar el score
                : item, // Dejar el objeto igual si no es el último
          );
          return {
            ...prevState,
            stateBg: decision,
            message: decision === 'bg-correct' ? '¡CORRECTO!' : '¡PASO!',
            selectedCard: randomCard,
            availableCards: updatedAvailableCards,
            score: updatedScore,
          };
        } else {
          return {
            ...prevState,
            stateBg: decision,
            message: decision === 'bg-correct' ? '¡CORRECTO!' : '¡PASO!',
            selectedCard: randomCard,
            availableCards: updatedAvailableCards,
          };
        }
      });
    }

    if (availableCardsLength === 0) {
      endGame();
    }
  };

  const getLevels = async () => {
    try {
      const url = '/levels';
      let response = await api.get(url);
      const data = response?.data?.data || [];
      setStateLevels(data);
    } catch (error) {
      console.log(error);
    }
  };

  const onClickTeam = team => {
    setStateTeams(
      stateTeams.map(t =>
        t.id === team.id
          ? {...t, selected: !team.selected}
          : {...t, selected: false},
      ),
    );
  };

  const onClickLevel = level => {
    setStateLevels(
      stateLevels.map(e =>
        e.id === level.id
          ? {...e, selected: !level.selected}
          : {...e, selected: false},
      ),
    );
  };

  const onClickCard = option => {
    if (option === '+') {
      const maxCards = stateLevel?.cards?.length || 0;
      if (stateConfigGame.maxCards < maxCards) {
        setStateConfigGame(prevState => {
          return {
            ...prevState,
            maxCards: prevState.maxCards + 1,
          };
        });
      }
    } else {
      if (stateConfigGame.maxCards > 1) {
        setStateConfigGame(prevState => {
          return {
            ...prevState,
            maxCards: prevState.maxCards - 1,
          };
        });
      }
    }
  };

  const onClickTime = option => {
    if (option === '+') {
      const maxTime = 300;
      if (stateConfigGame.time < maxTime) {
        setStateConfigGame(prevState => {
          return {
            ...prevState,
            time: prevState.time + 5,
          };
        });
      }
    } else {
      if (stateConfigGame.time > 10) {
        setStateConfigGame(prevState => {
          return {
            ...prevState,
            time: prevState.time - 5,
          };
        });
      }
    }
  };

  const onNavTeam = () => {
    soundButton.current.play().catch(error => {
      console.log('Error al reproducir el sonido:', error);
    });
    const team = stateTeams.find(e => e.selected);
    setStateTeam(team);
  };
  const onNavLevel = () => {
    soundButton.current.play().catch(error => {
      console.log('Error al reproducir el sonido:', error);
    });
    const level = stateLevels.find(e => e.selected);
    const cantCard = level?.cards?.length || 0;
    if (cantCard > 0) {
      setStateConfigGame(prevState => {
        return {
          ...prevState,
          maxCards: cantCard,
          time: 60,
        };
      });
      setStateLevel(level);
    }else{
        const msg = `El nivel ${level.name} no cuenta con tarjetas para jugar, intenta con otro.`
        _notify(msg,'danger')
    }
  };

  const onNavConfigGame = () => {
    soundButton.current.play().catch(error => {
      console.log('Error al reproducir el sonido:', error);
    });

    // const card = stateCards.find(e => e.selected);
    // const time = stateTime.find(e => e.selected);
    // setStateConfigGame(prevState => {
    //     return {
    //         ...prevState,
    //         maxCards: card.card,
    //         time: time.time,
    //     };
    // });

    startCountdown();
  };

  const btnBackTeams = () => {
    soundButton.current.play().catch(error => {
      console.log('Error al reproducir el sonido:', error);
    });
    setStateLevels(stateLevels.map(e => ({...e, selected: false})));
    setStateTeams(stateTeams.map(e => ({...e, selected: false})));
    setStateLevel(null);
    setStateTeam(null);
  };

  const btnBackLevels = () => {
    soundButton.current.play().catch(error => {
      console.log('Error al reproducir el sonido:', error);
    });
    setStateLevels(stateLevels.map(e => ({...e, selected: false})));
    setStateLevel(null);
  };

  const handleTimerEnd = () => {
    setTimerIsRunning(false);
    startGame();
  };

  const handleMMSTimerEnd = () => {
    // alert('El temporizador ha terminado!');
    // gameOver();
    //   startGame()
    console.log('assa');
    endGame();
  };

  const startCountdown = () => {
    setTimerIsRunning(true);
    setGameIsOver(true);
  };

  const startGame = () => {
    const level = stateLevel;
    const maxSelectableCards = stateConfigGame.maxCards;
    const allCards = level?.cards || [];
    const shuffledCards = allCards.sort(() => Math.random() - 0.5);
    const cards = shuffledCards.slice(0, maxSelectableCards);
    const teams = stateTeam.id;

    // Elegir una tarjeta aleatoria de las disponibles
    const randomIndex = Math.floor(Math.random() * cards.length);
    const randomCard = cards[randomIndex];

    const updatedAvailableCards = cards.filter(
      card => card.id !== randomCard.id,
    );

    //UPDATE STATE GAME

    setStateGame(prevState => {
      if (teams === 1) {
        return {
          ...prevState,
          stateBg: '',
          message: '',
          availableCards: updatedAvailableCards,
          selectedCard: randomCard,
          score: [
            {
              order: 1,
              team: 'Equipo 1',
              score: 0,
            },
          ],
        };
      } else {
        const lastScore = stateGame.score.reduce(
          (max, item) => (item.order > max.order ? item : max),
          stateGame.score[0],
        );
        const lastOrder = lastScore?.order || 0;

        return {
          ...prevState,
          message: '',
          stateBg: '',
          availableCards: updatedAvailableCards,
          selectedCard: randomCard,
          score: [
            ...prevState.score,
            {
              order: lastOrder + 1,
              team: `Equipo ${lastOrder + 1}`,
              score: 0,
            },
          ],
        };
      }
    });

    setIsStaging(true);
  };

  const restartGame = () => {
    setStateLevels(stateLevels.map(e => ({...e, selected: false})));
    setStateTeams(stateTeams.map(e => ({...e, selected: false})));
    setStateLevel(null);
    setStateTeam(null);
    setTimerIsRunning(false);
    setGameIsOver(false);
    setStateGame(prevState => {
      return {
        ...prevState,
        message: '',
        availableCards: [],
        selectedCard: null,
        score: [],
        stateBg: '',
      };
    });
  };

  const endGame = () => {
    setGameIsOver(true);
    setIsStaging(false);
  };

  const onTransitionEnd = () => {
    console.log('entro al end transition');
    setStateGame(prevState => {
      return {
        ...prevState,
        message: '',
        stateBg: '',
      };
    });
  };

  const getRandomColor = () => {
    const colors = ['bg-correct', 'bg-skip'];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  return (
    <>
      {!isStaging && !timerIsRunning && !gameIsOver && (
        <div
          onClick={() => openSlideOver()}
          style={{
            position: 'absolute',
            // backgroundColor: '#f2f2f2',
            width: 42,
            height: 42,
            top: 2,
            right: 4,
            // borderRadius: '0px 0px 16px 16px',
            // bo
          }}>
          <img
            style={{
              width: '100%',
              height: '100%',
            }}
            src={iconMenu}
            alt={'menu'}
          />
        </div>
      )}

      {/*<div*/}
      {/*  style={{*/}
      {/*    background: 'transparent',*/}
      {/*    width: '100%',*/}
      {/*    height: 100,*/}
      {/*    position: 'absolute',*/}
      {/*    top: 0,*/}
      {/*    display: 'flex',*/}
      {/*    alignItems: 'center',*/}
      {/*    justifyContent: 'space-between',*/}
      {/*  }}>*/}
      {/*  <div*/}
      {/*    onClick={() => getNextItem('bg-skip')}*/}
      {/*    style={{*/}
      {/*      background: 'red',*/}
      {/*      width: 100,*/}
      {/*      height: 100,*/}
      {/*      // position: 'absolute',*/}
      {/*      // top: 0,*/}
      {/*      // right: 0,*/}
      {/*    }}></div>*/}
      {/*  <div*/}
      {/*    onClick={() => getNextItem('bg-correct')}*/}
      {/*    style={{*/}
      {/*      background: 'green',*/}
      {/*      width: 100,*/}
      {/*      height: 100,*/}
      {/*      // position: 'absolute',*/}
      {/*      // top: 0,*/}
      {/*      // right: 0,*/}
      {/*    }}></div>*/}
      {/*</div>*/}

      {(!stateOrientation.deviceOrientationPermission ||
        !stateOrientation.orientationActive) && (
        <Orientation
          deviceOrientationPermission={
            stateOrientation.deviceOrientationPermission
          }
          orientationActive={stateOrientation.orientationActive}
          getDeviceOrientationPermission={() =>
            getDeviceOrientationPermission()
          }
        />
      )}

      {stateOrientation.deviceOrientationPermission &&
        stateOrientation.orientationActive &&
        !timerIsRunning &&
        !isStaging &&
        !gameIsOver && (
          <Options
            stateTeams={stateTeams}
            stateTeam={stateTeam}
            onClickTeam={team => onClickTeam(team)}
            onNavTeam={() => onNavTeam()}
            stateLevels={stateLevels}
            stateLevel={stateLevel}
            onClickLevel={level => onClickLevel(level)}
            onNavLevel={() => onNavLevel()}
            btnBackTeams={() => btnBackTeams()}
            btnBackLevels={() => btnBackLevels()}
            stateConfigGame={stateConfigGame}
            // stateCards={stateCards}
            // stateTime={stateTime}
            onNavConfigGame={() => onNavConfigGame()}
            onClickCard={option => onClickCard(option)}
            onClickTime={option => onClickTime(option)}
          />
        )}

      {timerIsRunning && (
        <Timer
          title="Coloca el dispositivo en la frente"
          time={4}
          isRunning={timerIsRunning}
          callback={handleTimerEnd}
        />
      )}

      {isStaging && gameIsOver && (
        <GameScene
          isStaging={isStaging}
          stateGame={stateGame}
          stateConfigGame={stateConfigGame}
          callback={handleMMSTimerEnd}
          onTransitionEnd={() => onTransitionEnd()}
        />
      )}

      {(gameIsOver && !isStaging && !timerIsRunning) && (
        <Score
          stateTeam={stateTeam}
          score={stateGame.score}
          restartGame={() => restartGame()}
          startCountdown={() => startCountdown()}
          soundButton={soundButton}
        />
      )}

      <SlideOver isOpen={isSlideOverOpen} onClose={closeSlideOver} />
    </>
  );
};
export default Game;
