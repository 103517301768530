import styles from './styles.css';

const Score = ({
  stateTeam,
  score,
  restartGame,
  soundButton,
  startCountdown,
}) => {
  const getLastTeam = () => {
    const lastScore = score.reduce(
      (max, item) => (item.order > max.order ? item : max),
      score[0],
    );
    const lastTeam = lastScore;

    return lastTeam;
  };

  const handleRestartGame = () => {
    soundButton.current.play().catch(error => {
      console.log('Error al reproducir el sonido:', error);
    });
    restartGame();
  };

  const maxScore = Math.max(...score.map(s => s.score));

  return (
    <div className={'wrap-container-score'}>
      {stateTeam?.id === score.length ? (
        <div className={'container-scores'}>
          <p className={'final-score'}>Puntuación total</p>
          <div className={'score-teams'}>
            {score
              .sort((a, b) => b.score - a.score)
              .map((s, index) => (
                <div className={`score-team ${s.score === maxScore && 'winner'}`}>
                  <span className={'name'}>{s.team}</span>
                  <span className={'score'}>{s.score}</span>
                </div>
              ))}
          </div>
          <div onClick={() => handleRestartGame()} className={'btn-start-game'}>
            Volver a Jugar
          </div>
        </div>
      ) : (
        <div className={'container-score'}>
          <p className={'name'}>{getLastTeam()?.team}</p>
          <p className={'score'}>Puntaje: {getLastTeam()?.score}</p>
          <div className={'next-team'}>
            <p
              className={
                'next-team-name'
              }>{`Equipo ${getLastTeam()?.order + 1}`}</p>
            <div onClick={startCountdown} className={'btn-next-team'}>
              Comenzar
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Score;
