import React, {useContext, useEffect, useState} from 'react';
import {Route, Navigate} from 'react-router-dom';
import {DataContext} from '../context/DataContext';

// const ProtectedRoute = ({element, ...rest}) => {
//   const {user} = useContext(DataContext);
//
//   return (
//     <Route
//         {...rest}
//         element={user ? element : <Navigate to="/login" />}
//     />
//   );
// };
//
// export default ProtectedRoute;
//
// import React, { useContext } from 'react';
// import { Route, Navigate } from 'react-router-dom';
// import { DataContext } from '../context/DataContext';
//
// const ProtectedRoute = ({ element, ...rest }) => {
//     const { user } = useContext(DataContext);
//
//     // Si el usuario está autenticado, renderiza el componente, si no redirige a /login
//     return (
//         <Route
//             {...rest}  // Pasa el resto de las propiedades (como path, exact, etc.)
//             element={user ? element : <Navigate to="/login" />}  // Renderiza el componente o redirige
//         />
//     );
// };
//
// export default ProtectedRoute;


// import {
//     Routes,
//     Route,
//     NavLink,
//     Navigate,
//     useNavigate,
// } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const { user } = useContext(DataContext);

    if (!user) {
        return <Navigate to="/login" replace />;
    }

    return children;
};
export default ProtectedRoute;