import axios from 'axios';
import {BASE_URL} from '../env';
import {getUser} from './auth-service';

const api = axios.create({
  baseURL: BASE_URL,
});

api.interceptors.request.use(
  async config => {
    const user = await getUser();
    const accessToken = user?.access_token || '';
    config.headers['ngrok-skip-browser-warning'] = true;
    config.headers['Authorization'] = `Bearer ${accessToken}`;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export default api;
