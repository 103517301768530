import React, {useEffect, useState} from 'react';
import './styles.css';
import countdown from '../../sounds/countdown-3.mp3';

const Timer = ({title, time, isRunning, callback}) => {
  const [seconds, setSeconds] = useState(time);
  const sound = new Audio(countdown);

  useEffect(() => {
    sound.play();
  }, []);
  useEffect(() => {
    let interval;

    if (isRunning && seconds > 0) {
      interval = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      clearInterval(interval);
      if (callback) callback();
    }

    return () => clearInterval(interval);
  }, [isRunning, seconds, callback]);

  useEffect(() => {
    setSeconds(time); // Reinicia el contador al tiempo inicial cuando cambia 'time'
  }, [time]);

  return (
    <div className={'container-timer'}>
      <div className={'timer'}>
        <p className={'title'}>{title}</p>
        <span className={'time'}>{seconds - 1}</span>
      </div>
    </div>
  );
};

export default Timer;
