import React from 'react';
import './styles/variables.css';
import 'animate.css';
import './styles/login.css';
import './styles/game.css';
import './styles/react-notifications-component/notification.scss';
import './styles/global.css';
import './styles/responsive.css';
import {DataProvider} from './context/DataContext';
import Navigation from './navigation';
import {ReactNotifications} from 'react-notifications-component';
import Portal from './components/Portal/Portal';

function App() {
  return (
    <DataProvider>

      <Navigation />
        <Portal id="portal-notification">
            <ReactNotifications />
        </Portal>
    </DataProvider>
  );
}

export default App;
