import React, {useContext, useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Login from '../screens/Auth/Login';
import Game from '../screens/Game/Game';
import {DataContext} from '../context/DataContext';
import {getUser} from '../utils/auth-service';
import {useAuthInterceptor} from '../hooks/useAuthInterceptor';
import ProtectedRoute from '../utils/ProtectedRoute';

const Navigation = () => {
  useAuthInterceptor();
  const {user, setUser} = useContext(DataContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkUser();
  }, []);

  const checkUser = async () => {
    const localUser = await getUser();
    setUser(localUser);
    if (!localUser) {
    }
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Login />} path={'/login'} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Game />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Navigation;
