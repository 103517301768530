import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import logo from '../../images/logo.png';
import {DataContext} from '../../context/DataContext';
import {_loader, _removeLoader, _notify} from '../../utils/helpers';
import api from '../../utils/api';
import Card from '../../components/Card/Card';

const Login = () => {
  const {user, setUser} = useContext(DataContext);
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState('');

  useEffect(() => {
    if (user !== null) {
      navigate('/');
    }
  }, [user]);

  const onChangeUsername = e => {
    setUsername(e.target.value);
  };
  const onChangePassword = e => {
    setPassword(e.target.value);
  };

  const login = async () => {
    try {
      if (!username) {
        _notify('Introduce el usuario', 'danger');
        return;
      }
      if (!password) {
        return _notify('Introduce la contraseña', 'danger');
      }

      _loader();
      const url = '/auth/login';

      const payload = {
        username: username,
        password: password,
        origin: 'app',
      };
      let response = await api.post(url, payload);
      response = response?.data?.data || null;
      localStorage.setItem('user', JSON.stringify(response));
      setUser(response);
      _removeLoader();
      navigate('/');
    } catch (error) {
      _removeLoader();
    }
  };

  if (user !== null) {
    return null;
  } else {
    return (
      <div className={'login'}>
        <Card>
          <div
            className={'login-container'}
            style={{
              flex: 1,
            }}>
            <div className={'login-header'}>
              <div>
                <p className={'title'}>Ingresar</p>
                <p className={'subtitle'}>Indica tu usuario y contraseña</p>
                <p style={{marginBottom: '0.6rem'}} className={'subtitle'}>
                  para entrar
                </p>
              </div>
              <img src={logo} alt={'logo'} className={'logo'} />
            </div>
            <div>
              <input
                placeholder={'Usuario'}
                className={'username'}
                type={'text'}
                onChange={e => onChangeUsername(e)}
              />
              <div className={'container-password'}>
                <input
                    style={{marginBottom:0}}
                  placeholder={'Contraseña'}
                  className={'password'}
                  type={showPassword ? 'text' : 'password'}
                  onChange={e => onChangePassword(e)}
                />

                {!showPassword && (
                  <div
                    onClick={() => setShowPassword(true)}
                    className={'button-eye'}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-6 icon-eye">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                    </svg>
                  </div>
                )}

                {showPassword && (
                  <div
                    onClick={() => setShowPassword(false)}
                    className={'button-eye'}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-6">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                      />
                    </svg>
                  </div>
                )}
              </div>
              <button className={'btn-login'} onClick={e => login(e)}>
                Entrar
              </button>
            </div>
          </div>
        </Card>
      </div>
    );
  }
};

export default Login;
