import styles from './styles.css';
import Card from '../Card/Card';
import logo from '../../images/logo.png';
import Button from '../Button/Button';

const Options = ({
  stateTeams,
  stateTeam,
  stateLevels,
  stateLevel,
  onClickTeam,
  onClickLevel,
  onNavTeam,
  onNavLevel,
  btnBackTeams,
  btnBackLevels,
  stateConfigGame,
  stateCards,
  stateTime,
  onNavConfigGame,
  onClickCard,
  onClickTime,
}) => {
  const showNavTeams = !!stateTeams.find(e => e.selected);
  const showNavLevels = !!stateLevels.find(e => e.selected);
  const showNavConfig = true;

  const handleOnClickTeam = team => {
    onClickTeam(team);
  };
  const handleOnClickLevel = level => {
    onClickLevel(level);
  };

  const handleOnClickCard = option => {
    onClickCard(option);
  };
  const handleOnClickTime = option => {
    onClickTime(option);
  };

  return (
    <div className={'wrap-container-options'}>
      <Card width={'90%'}>
        {!stateTeam && (
          <div className={'options'}>
            <div className={'header'}>
              <div>
                <p className={'title'}>Comenzar a jugar</p>
                <p className={'subtitle'}>
                  Elige el número de equipos que jugarán
                </p>
              </div>
              <img src={logo} alt={'logo'} className={'logo'} />
            </div>
            <div className={'container-options'}>
              {stateTeams.map(team => (
                <Button
                  key={team.id}
                  order={team.id}
                  title={team.name}
                  selected={team.selected}
                  onClickButton={() => handleOnClickTeam(team)}
                />
              ))}
            </div>

            <div className={'navigation'}>
              <div />
              {showNavTeams && (
                <button onClick={onNavTeam} className="btn-next">
                  {'Siguiente >'}
                </button>
              )}
            </div>
          </div>
        )}

        {stateTeam && !stateLevel && (
          <div className={'options'}>
            <div className={'header'}>
              <div>
                <p className={'title'}>Selecciona el nivel</p>
                <p className={'subtitle'}>
                  Elige el nivel para comenzar a jugar
                </p>
              </div>
              <img src={logo} alt={'logo'} className={'logo'} />
            </div>
            <div className={'container-options levels'}>
              {stateLevels.map(level => (
                <Button
                  key={level.id}
                  order={level.id}
                  title={level.name}
                  selected={level?.selected}
                  onClickButton={() => handleOnClickLevel(level)}
                />
              ))}
            </div>
            <div className={'navigation'}>
              <button onClick={btnBackTeams} className="btn-back">
                {'< Atrás'}
              </button>

              <div />
              {showNavLevels && (
                <button onClick={onNavLevel} className="btn-next">
                  {'Siguiente >'}
                </button>
              )}
            </div>
          </div>
        )}

        {stateTeam && stateLevel && stateConfigGame && (
          <div className={'options'}>
            <div className={'header'}>
              <div>
                <p className={'title'}>Configura tu juego</p>
                <p className={'subtitle'}>
                  Elige la cantidad de tarjetas/palabras y tiempo limite para
                  comenzar a jugar.
                </p>
              </div>
              <img src={logo} alt={'logo'} className={'logo'} />
            </div>
            <div className={'container-options'}>
              <div className={'container-config'}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: 25,
                  }}>
                  <svg
                    style={{marginRight: 5}}
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#41859A">
                    <path d="m159-168-34-14q-31-13-41.5-45t3.5-63l72-156v278Zm160 88q-33 0-56.5-23.5T239-160v-240l106 294q3 7 6 13.5t8 12.5h-40Zm206-4q-32 12-62-3t-42-47L243-622q-12-32 2-62.5t46-41.5l302-110q32-12 62 3t42 47l178 488q12 32-2 62.5T827-194L525-84Zm-86-476q17 0 28.5-11.5T479-600q0-17-11.5-28.5T439-640q-17 0-28.5 11.5T399-600q0 17 11.5 28.5T439-560Zm58 400 302-110-178-490-302 110 178 490ZM319-650l302-110-302 110Z" />
                  </svg>

                  <div className={'controls'}>
                    <div
                      onClick={() => handleOnClickCard('-')}
                      className={'control menos'}>
                      -
                    </div>
                    <div className={'cant'}>{stateConfigGame.maxCards}</div>
                    <div
                      onClick={() => handleOnClickCard('+')}
                      className={'control mas'}>
                      +
                    </div>
                  </div>
                </div>

                <div style={{display: 'flex', alignItems: 'center'}}>
                  <svg
                    style={{marginRight: 5}}
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#41859A">
                    <path d="M360-840v-80h240v80H360Zm80 440h80v-240h-80v240Zm40 320q-74 0-139.5-28.5T226-186q-49-49-77.5-114.5T120-440q0-74 28.5-139.5T226-694q49-49 114.5-77.5T480-800q62 0 119 20t107 58l56-56 56 56-56 56q38 50 58 107t20 119q0 74-28.5 139.5T734-186q-49 49-114.5 77.5T480-80Zm0-80q116 0 198-82t82-198q0-116-82-198t-198-82q-116 0-198 82t-82 198q0 116 82 198t198 82Zm0-280Z" />
                  </svg>

                  <div className={'controls'}>
                    <div
                      onClick={() => handleOnClickTime('-')}
                      className={'control menos'}>
                      -
                    </div>
                    <div className={'cant'}>{stateConfigGame.time}”</div>
                    <div
                      onClick={() => handleOnClickTime('+')}
                      className={'control mas'}>
                      +
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={'navigation'}>
              <button onClick={btnBackLevels} className="btn-back">
                {'< Atrás'}
              </button>

              <div />
              <button onClick={onNavConfigGame} className="btn-next">
                {'Siguiente >'}
              </button>
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};

export default Options;
