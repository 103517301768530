import React, {useEffect, useState, useRef} from 'react';
import './styles.css';
import countdown from '../../sounds/countdown-10.mp3';

const MMSTimer = ({minutes, isRunning, callback}) => {
  // const [timeLeft, setTimeLeft] = useState(minutes * 60); // Convertir minutos a segundos
  const [timeLeft, setTimeLeft] = useState(minutes); // Convertir minutos a segundos
  // const sound = new Audio(countdown);
    const sound = useRef(new Audio(countdown)); // Usar useRef para mantener la instancia del audio

  useEffect(() => {
    let interval;

    if (isRunning && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      clearInterval(interval);
      if (callback) callback();
    }

    return () => clearInterval(interval);
  }, [isRunning, timeLeft, callback]);

  useEffect(() => {
    // setTimeLeft(minutes * 60); // Reinicia el contador al tiempo inicial cuando cambia 'minutes'
    setTimeLeft(minutes); // Reinicia el contador al tiempo inicial cuando cambia 'minutes'
  }, [minutes]);


    // Reproducir sonido cuando el tiempo llega a 10 segundos
    useEffect(() => {
        if (timeLeft === 10) {
            sound.current.play().catch(error => {
                console.log('Error al reproducir el sonido:', error);
            });
        }
    }, [timeLeft]);

  // Formato MM:SS
  const formatTime = seconds => {
    const mins = String(Math.floor(seconds / 60)).padStart(2, '0');
    const secs = String(seconds % 60).padStart(2, '0');
    return `${mins}:${secs}`;
  };

  // Estilo condicional basado en el tiempo restante
  const timerClass = timeLeft <= 10 ? 'time red' : 'time';
  // if (timeLeft === 10) {
  //   sound.play();
  // }

  return (
    <div className={'mmsTimer'}>
      <span className={timerClass}>{formatTime(timeLeft)}</span>
    </div>
  );
};

export default MMSTimer;
