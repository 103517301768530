import React, {useContext, useState} from 'react';
import './styles.css';
import {logout} from '../../utils/auth-service';
import {DataContext} from '../../context/DataContext';
import {useNavigate} from 'react-router-dom';
import {VERSION} from "../../env"; // Importamos los estilos CSS

const SlideOver = ({isOpen, onClose}) => {
  const {user, setUser} = useContext(DataContext);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      setUser(null);
      navigate('/login');
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div
      className={`slideover-overlay ${isOpen ? 'active' : ''}`}
      onClick={onClose}>
      <div
        className={`slideover ${isOpen ? 'slide-in' : ''}`}
        onClick={e => e.stopPropagation()}>
        <div className="slideover-header">
          <p className={'role'}>{user?.role?.alias}</p>

          <button className="close-btn" onClick={onClose}>
            X
          </button>
        </div>
        <div className="slideover-content">
          <div className={'menu-sidebar'} onClick={() => handleLogout()}>
            <svg
              style={{width: 25, height: 25, color: 'crimson'}}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="size-6">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15"
              />
            </svg>

            <span>Cerrar Sesión</span>
          </div>


            <span className={'version'}>{VERSION}</span>
        </div>
      </div>
    </div>
  );
};

export default SlideOver;
