import styles from './styles.css';

const Orientation = ({
  deviceOrientationPermission,
  orientationActive,
  getDeviceOrientationPermission,
}) => {
  return (
    <div className={'container-orientation'}>
      {!deviceOrientationPermission && (
        <div className={'permission-orientation'}>
          <p className={'text'}>POR FAVOR ACTIVE LOS PERMISOS DE ORIENTACIÓN</p>
          <div className={'button'} onClick={getDeviceOrientationPermission}>
            Activar Permisos
          </div>
        </div>
      )}
      {deviceOrientationPermission && !orientationActive && (
        <div className={'orientation'}>
          <p className={'orientation-text'}>
            POR FAVOR DESACTIVE EL BLOQUEO DE ORIENTACIÓN
          </p>
        </div>
      )}
    </div>
  );
};

export default Orientation;
